<template>
    <div class="relative">
        <select class="block w-full appearance-none bg-gray-50 border border-gray-300 py-2 px-4 pr-8 rounded focus:outline-none focus:bg-white focus:border-grey"
                :value="modelValue"
                @change="$emit('update:modelValue', $event.target.value)">
            <option :value="option" v-for="option in options">
                <slot :option="option" name="option"></slot>
            </option>
        </select>
    </div>
</template>

<script>
  export default {
    compatConfig: { COMPONENT_V_MODEL: false },
    props: {
      modelValue: {
        default: null,
      },
      options: {
        type: Array,
        required: true,
      },
    },
  };
</script>

<style>

</style>