<template>
    <div class="relative flex flex-col md:flex-row" v-click-away="close" v-if="modelValue">
        <div class="relative md:w-1/2 md:mr-1">
            <ui-select :options="allowedMonths" v-model="monthName">
                <template v-slot:option="{option}">
                    {{ option }}
                </template>
            </ui-select>
        </div>
        <div class="relative md:w-1/2 mt-2 md:mt-0 md:ml-1">
            <template v-if="!freeInput">
                <ui-select :options="allowedYears" v-model="year">
                    <template v-slot:option="{option}">
                        {{ option }}
                    </template>
                </ui-select>
            </template>
            <template v-else>
                <text-input type="number" @update:modelValue="(year) => this.year = year"></text-input>
            </template>
        </div>
    </div>
</template>
<script>
import moment from 'moment';
import dayjs from 'dayjs';
import {directive as ClickAway} from 'vue3-click-away';

import UiSelect from '@/components/ui/Select.vue';
import TextInput from '@/components/forms/TextInput.vue';

export default {
    directives: {
      ClickAway,
    },
    data() {
      return {
        monthName: null,
        month: null,
        year: null,
        monthOpen: false,
        yearOpen: false,
        originalYear: null,
      };
    },
    compatConfig: { COMPONENT_V_MODEL: false },
    props: {
      modelValue: {
        type: Object, // dayjs date
      },
      freeInput: {
        type: Boolean,
        default: false,
      },
    },

    watch: {
      modelValue: {
        immediate:true,
        handler(value) {
          const validDate = value instanceof dayjs ? value.toDate() : value;

          let date = moment.utc(validDate).startOf('month');
          this.month = date.month();
          this.year = date.year();
          this.monthName = this.allowedMonths[this.month];
        },
      },
      monthName: {
        handler(value) {
          this.month = this.allowedMonths.indexOf(value);
        }
      },
      date(value) {
        this.$emit('update:modelValue', moment(value).utc(value).startOf('month').toDate());
      },
      selectedMonth() {
        this.scrollMonthIntoView();
      },
      selectedYear() {
        this.scrollYearIntoView();
      },
    },

    methods: {
      toggleMonthEditor() {
        this.monthOpen = !this.monthOpen;
        this.scrollMonthIntoView();
      },
      toggleYearEditor() {
        this.yearOpen = !this.yearOpen;
        this.scrollYearIntoView();
      },
      close() {
        this.open = false;
      },
      scrollMonthIntoView() {
        let monthEl = this.$refs[this.selectedMonth];

        if (monthEl) {
          monthEl[0].scrollIntoView();
        }
      },
      scrollYearIntoView() {
        let yearEl = this.$refs[this.selectedYear];

        if (yearEl) {
          yearEl[0].scrollIntoView();
        }
      },
    },

    computed: {
      date() {
        return moment.utc().startOf('day').startOf('month').year(this.year).month(this.month).toDate();
      },
      allowedMonths() {
        let currentMonth = moment().utc().month();
        let months = moment.months();

        return months;
        // return months.slice(currentMonth, months.length)
      },
      allowedYears() {
        let currentYear = moment().utc().year() - 2;
        currentYear = currentYear - this.originalYear > 2 ? this.originalYear : currentYear;
        let years = [];

        for (var i = 0; i < 10; i++) {
          years.push(currentYear + i);
        }

        return years;
      },
      selectedMonth() {
        let possibleMonths = moment.months();

        return possibleMonths[this.month];
      },
      selectedYear() {
        return moment.utc(this.date).year();
      },
    },

    mounted() {
      this.originalYear = this.year; // store the original year for traversal if it's less than the usual default number of years
    },

    components: {
      TextInput,
      UiSelect,
    },
  };

</script>
<style>

</style>